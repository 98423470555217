import React from "react";
import './video.css';

export default function Video() {
    return (
        <div className="video-wrapper">
            <video controls>
                <source src='media/images/mariage/Elise&Yoann_Confessionnal.mp4'/>
            </video>
        </div>
    );
}
