import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./styles.css";

import App from "./App";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("rootElement not found");

render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    rootElement
);
