import React from "react";
import { Gallery as ReactGallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images as PHOTOS, CustomImage } from "./photos";

export default function Gallery() {
    const [index, setIndex] = React.useState(-1);
    const currentImage = PHOTOS[index];
    const nextIndex = (index + 1) % PHOTOS.length;
    const nextImage = PHOTOS[nextIndex] || currentImage;
    const prevIndex = (index + PHOTOS.length - 1) % PHOTOS.length;
    const prevImage = PHOTOS[prevIndex] || currentImage;

    const [images, setImages] = React.useState(PHOTOS);
    // const hasSelected = images.some((image) => image.isSelected);

    const handleClick = (index: number, item: CustomImage) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const handleSelect = (index: number) => {
        const nextImages = images.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        setImages(nextImages);
    };

    // const handleSelectAllClick = () => {
    //     const nextImages = images.map((image) => ({
    //         ...image,
    //         isSelected: !hasSelected,
    //     }));
    //     setImages(nextImages);
    // };

    // const handleDownloadSelected = () => {
    //
    // };

    return (
        <div>
            <ReactGallery
                images={images}
                onSelect={handleSelect}
                onClick={handleClick}
                enableImageSelection={false}
            />
            {!!currentImage && (
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </div>
    );
}
