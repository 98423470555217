import * as React from "react";
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import Gallery from "./Gallery";
import Video from "./Video";

const Layout = () => {
    return (
        <div>
            <header>
                <nav className="pt-1">
                    <Link to="/" className="mx-2">Galerie photos</Link>
                    <Link to="/confessionnal" className="mx-2">Voir la vidéo du confessionnal</Link>
                </nav>
            </header>
            <Outlet />
        </div>
    );
}

const EmptyLayout = () => {
    return (
        <div/>
    );
}

const disableRightClick = () => {
    const videoElements = document.getElementsByClassName('video-wrapper');
    for (let i = 0; i < videoElements.length; ++i) {
        videoElements[i].addEventListener("contextmenu", (event) => {
            event.preventDefault();
        });
    }
    const headerElements = document.getElementsByTagName('header');
    for (let i = 0; i < headerElements.length; ++i) {
        headerElements[i].addEventListener("contextmenu", (event) => {
            event.preventDefault();
        });
    }
};

export default function App() {
    const location = useLocation();
    React.useEffect(() => {
        disableRightClick();
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Gallery/>}/>
                <Route path="confessionnal" element={<Video/>}/>
                <Route path="*" element={<EmptyLayout/>}/>
            </Route>
        </Routes>
    );
}
